import * as React from "react"
import Seo from "../components/seo"
import "../styles/styles.sass"
import Header from "../components/header"
import Footer from "../components/footer"
import {
  LighthouseStudy, CueHitStudy, SnuppsStudy, ChessUpStudy
} from "../views/home/homeStudies"


const CaseStudiesPage = () => {
  return (
  <main className="mercuryView homeView caseStudiesView">
    <Seo
      title="Phil Amour — Case Studies"
      description="Read my case studies to learn more about my design process."
      image="/imageSEOStudies.jpg"
    />
    
    <div className="headerSpacer"/>

    <Header headerType="subview" />

    <div className="headerSpacer"/>

    <div className="mercuryContent">
      <section className="mercurySection headerSection">
        <div className="sectionContent">
          <h1 className="headerTitle">Case Studies</h1>
          <p className="headerSubtitle">More coming soon!</p>
        </div>
      </section>

      <section className="caseStudiesList mercurySection">
        <div className="sectionContent">
          <LighthouseStudy />
          <CueHitStudy />
          <ChessUpStudy />
          <SnuppsStudy />
        </div>
      </section>

    </div>
    
    <Footer />
  </main>
  )
}

export default CaseStudiesPage
